import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["card", "button"];

  connect() {
    this.cards = this.cardTargets;
    this.activeButton = null;
  }

  selectCard(event) {
    const selectedCard = event.currentTarget;
    const input = selectedCard.querySelector("input");

    const activeClasses = ["border-1", "border-primary", "shadow-xl"];

    this.cards.forEach(card => {
      card.classList.remove(...activeClasses);
      card.querySelector("input").checked = false;
    });

    selectedCard.classList.add(...activeClasses);
    input.checked = true;

    const selectedButton = event.currentTarget.querySelector(".btn-primary");

    if (selectedButton) {
      this.buttonTargets.forEach(button => {
        button.classList.remove("btn-outline-primary");
        button.classList.add("btn-primary");
      });

      selectedButton.classList.remove("btn-primary");
      selectedButton.classList.add("btn-outline-primary");
    }
  }
}
